<template>
          <div class="md-layout">
              
            <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                <div class="info">
                  <div class="icon icon-primary">
                    <md-icon>pin_drop</md-icon>
                  </div>
                  <h4 class="info-title">
                    1. Set your destination
                  </h4>
                  <p>
                    We listen to your organisations needs and goals, ask questions to better understand your organisation, and create a strategy together
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>work</md-icon>
                    </div>
                    <h4 class="info-title">
                      2. Prepare for the journey
                    </h4>
                    <p>
                      We offer advice, provide a variety of solutions that fit your goals, and present these to you
                    </p>
                  </div>
                </div>
              
              <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <img src="@/assets/img/FC_bird.svg" width="110px" class="fc-bird" />
                    </div>
                    <h4 class="info-title">
                      3. Take off
                    </h4>
                    <p>
                      We begin working on your solution, communicating with you throughout the process, and adjusting where needed to ensure we meet your requirements
                    </p>
                  </div>
                </div>
          </div>
</template>

<script>

export default {
  components: {
  }
};
</script>
<style lang="scss">
</style>