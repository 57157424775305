<template>
  <div class="main-raised-page-wrapper">
    <div id="main-panel" class="main main-raised">
      <div class="section">
        <div class="container">
          
          <div class="md-layout-item md-medium-size-100">
            <h1 class="title">
              Documents For Clients
            </h1>
                <h5>Information</h5>
                    <p><a href="https://cdn3.firecrestsystems.com/FC_Firecrest/Images/FC_New-Client-Account.pdf" target="_blank">
                      <i class="material-icons">article</i> Client Application Form</a></p>
                    <p><a href="https://cdn3.firecrestsystems.com/FC_Firecrest/Images/FC_Terms-Of-Trade.pdf" target="_blank">
                      <i class="material-icons">task</i> Firecrest Terms Of Trade</a></p>
                    <p><a href="https://cdn3.firecrestsystems.com/FC_Firecrest/Images/FC_Client-Annual-Agreement.pdf" target="_blank">
                      <i class="material-icons">task</i> Firecrest Annual Agreement</a></p>
                    <p><a href="https://cdn3.firecrestsystems.com/FC_Firecrest/Images/FC_MDS_SLA.pdf" target="_blank">
                      <i class="material-icons">task</i> Firecrest Managed Device SLA</a></p>
              </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  

  <script>
  import Mixins from "@/plugins/material-kit-mixins";
  
  export default {
    name: "Privacy",
    mixins: [Mixins.HeaderImage],
    data() {
      return {
        image: require("@/assets/img/FC-bk1.jpg")
      };
    }
  };
  </script>
  
  <style scoped>
  
  </style>
