<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Point of Care Health Online
            </h1><img src="@/assets/img/ALT-POCh-logo-FC.png">
          </div>
        </div>
      </div>
    </parallax>

    <div id="main-panel" class="main main-raised">
      <div class="section section-sections">
    <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
              <h2 class="title">Online software for healthcare professionals to manage patient health by providing easier access to testing and faster results.</h2>
              <h4>Firecrest Systems also has a growing point of care health department. One of our main modules, used in pharmacies across New Zealand, is the CPAMS programme. This programme is based on using INR Online, an award-winning web-based platform, to manage the treatment of patients on warfarin in pharmacies.
Our latest version of the platform is called POC (Point of Care) Health Online which is a fully cloud based system. This has been the introduction of our expanded service in other areas which include our second module, called “Gout Buster”. This is currently being trialled in 13 pharmacies across Counties Manukau to manage the treatment of patients suffering from gout by regular monitoring of their serum urate level and regulating their allopurinol doses. </h4>
                </div>
      <div class="section section-sections">
    <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 text-center"><br>
              
              <md-button class="md-primary md-lg" href="https://pochealth.online" target="_blank">
              Learn more from the POC Health website
              </md-button>
              </div>
              
              <div class="md-layout-item md-size-50 md-small-size-100  text-center">
                <a href="https://pochealth.online" target="_blank"><img src="@/assets/img/POCh-logo-FC.png"></a>
                </div>
          </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
export default {
  components: {
  },
  bodyClass: "contact-us",
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      
      image: require("@/assets/img/FC_456813301.jpg"),
    };
  }
};
</script>