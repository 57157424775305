<template>
  <div class="main-raised-page-wrapper">
    <div id="main-panel" class="main main-raised">
      <div class="section">
        <div class="container">
            <h1 class="title">
              Privacy Policy
            </h1>
  
          <p>
            Welcome to Firecrest Systems (the "Site"). This website privacy policy ("Policy") describes how Firecrest Systems and its subsidiaries ("We", or "we") may use and disclose personally identifiable information that we may collect about you through the Site ("Personal Information").
          </p>
  
          <ol>
            <li><a href="javascript:void(0)" @click="scrollToElement('A1')">Collections and uses of personal information</a></li>
            <li><a href="javascript:void(0)" @click="scrollToElement('A2')">Disclosures of personal information</a></li>
            <li><a href="javascript:void(0)" @click="scrollToElement('A3')">Revisions to the Policy</a></li>
            <li><a href="javascript:void(0)" @click="scrollToElement('A4')">Contact Information</a></li>
          </ol>
  
          <h2 id="A1" class="Space">1. Collections and uses of personal information</h2>
  
          <p>
            We respect each site visitor's right to personal privacy. To that end, we collect and use information throughout our website only as disclosed in this Privacy Policy. This statement applies solely to information collected on this website. For each visitor to our website, our web server automatically recognizes no information regarding the domain or e-mail address. The information we collect is:
          </p>
  
          <ul>
            <li>used to provide a requested service.</li>
            <li>used to improve the content of our web site.</li>
            <li>used to notify visitors about updates to our web site.</li>
            <li>never shared with other organizations for commercial purposes.</li>
          </ul>
  
          <p>
            In order to use this website, visitors and/or members are not required to complete our registration form.
          </p>
  
          <h2 id="A2" class="Space">2. Disclosures of personal information</h2>
  
          Firecrest Systems may disclose Personal Information to third parties. The disclosure to third parties would occur in the following circumstances:
  
          <ul>
            <li>We may disclose information to companies and individuals we employ to perform business functions and services on our behalf. Such functions may include, for example, hosting our Web servers, analyzing data, providing legal, accounting and marketing services.</li>
            <li>We may disclose information to the extent necessary or appropriate to government agencies, advisors, and other third parties in order to comply with applicable laws, the service of legal process, or if we reasonably believe that such action is necessary to (a) comply with the law requiring such disclosure; (b) protect the rights or property of Firecrest Systems or its affiliated companies; (c) prevent a crime or protect national security or (d) protect the personal safety of the users or the public.</li>
          </ul>
  
          <h2 id="A3" class="Space">3. Revisions to the Policy</h2>
  
          <p>
            Any changes to this privacy policy will be outlined in this section. If we change material terms in this Policy, we will provide notice of the revised policy for 30 days on the home page. This Policy is effective as of November 15, 2020.
          </p>
  
          <h2 id="A4" class="Space">4. Contact Information</h2>
  
          <p>
            If you have any questions about our website privacy policies, please feel free to contact us by email <a href="mailto:support@firecrest.co.nz">support@firecrest.co.nz</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  </template>
  

  <script>
  import Mixins from "@/plugins/material-kit-mixins";
  
  export default {
    name: "Privacy",
    mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
    data() {
      return {
        image: require("@/assets/img/FC-bk1.jpg")
      };
    }
  };
  </script>
  
  <style scoped>
  
  </style>
