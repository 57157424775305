<template>
  <div class="wrapper">
      <div class="md-layout">
        <div class="md-layout-item md-size-80 mx-auto md-small-size-100">
          <md-card>
            <el-carousel trigger="click" :interval="7000">

              <el-carousel-item>
              <div class="md-layout case-card">
                <div class="md-layout-item md-size-100">
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 ml-auto left">
                  <img src="@/assets/img/clients/2-colour-horozontal-Manawatu.png">
                  <h4 class="card-title text-center">
                    Te Pū Harakeke—Community Collective Manawatū
                  </h4>
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 mr-auto">
                  <h3>Supporting this not-for-profit organisation with MDS and web.</h3>
                  <md-button class="md-primary" href="#/stories">See more</md-button>
                </div>
              </div>
              </el-carousel-item>

              <el-carousel-item>
              <div class="md-layout case-card">
                <div class="md-layout-item md-size-100">
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 ml-auto left">
                  <img src="@/assets/img/clients/FreeFM89-Logo.png">
                  <h4 class="card-title text-center">
                    Free FM | Tuia Ngā Reo O Te Hapori
                  </h4>
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 mr-auto">
                  <h3>Providing web solutions and intergrating podcasting tools.</h3>
                  <md-button class="md-primary" href="#/stories">See more</md-button>
                </div>
              </div>
              </el-carousel-item>

              <el-carousel-item>
              <div class="md-layout case-card">
                <div class="md-layout-item md-size-100">
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 ml-auto left">
                  <h4 class="card-title text-center">
                    <img src="@/assets/img/clients/Coural-logo.png">
                  </h4>
                  <h4 class="card-title text-center">
                    Coural - Rural Couriers
                  </h4>
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 mr-auto">
                  <h3>Developing custom software and web solutions for business.</h3>
                </div>
              </div>
              </el-carousel-item>

              <el-carousel-item>
              <div class="md-layout case-card">
                <div class="md-layout-item md-size-100">
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 ml-auto left">
                  <h4 class="card-title text-center">
                    <img src="@/assets/img/clients/NZDSI-Logo.jpg">
                  </h4>
                  <h4 class="card-title text-center">
                    NZ Dermatological Society Inc
                  </h4>
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 mr-auto">
                  <h3>Supporting professional practice and development for health workers.</h3>
                </div>
              </div>
              </el-carousel-item>

              <el-carousel-item>
              <div class="md-layout case-card">
                <div class="md-layout-item md-size-100">
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 ml-auto left">
                  <h4 class="card-title text-center">
                    <img src="@/assets/img/clients/AM_Logo_Vertical_2019.png">
                  </h4>
                  <h4 class="card-title text-center">
                    Access Media NZ
                  </h4>
                </div>
                <div class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 mr-auto">
                  <h3>Providing podcasting tools and cloud software for access media stations.</h3>
                </div>
              </div>
              </el-carousel-item>

            </el-carousel>
          </md-card>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  components: {
  }
};
</script>

<style lang="css" scoped>
.md-card img{
  max-width:100%;
  width: auto !important;
  height: auto !important;
}
.case-card{
  display: flex;
  align-items: center;
  justify-content: center;
  height:80%;
  padding:20px;
}
.case-card .left{
  border-right: solid 1px #555 !important;
  height:70%;
}
@media all and (max-width: 580px) {
  .case-card .left {
    border-right: solid 1px white !important;
    height:auto !important;
  }
}
</style>
