<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Custom Software Development
            </h1>
          </div>
        </div>
      </div>
    </parallax>

    <div id="main-panel" class="main main-raised">
      <div class="section section-sections">
    <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
              <h2 class="title">Our Process</h2>
                </div>
            <div class="md-layout-item md-size-25 md-small-size-100 text-center">
                <div class="info">
                  <div class="icon icon-primary">
                    <md-icon>live_help</md-icon>
                  </div>
                  <h4 class="info-title">
                    Consultation
                  </h4>
                  <p>
                    We sit with you and learn about your requirements and current barriers, and produce a plan of action to get you moving.
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>polyline</md-icon>
                    </div>
                    <h4 class="info-title">
                      Design
                    </h4>
                    <p>
                      We create the solution of your vision, including modern practices, security, and effectual design.
                    </p>
                  </div>
                </div>
              
              <div class="md-layout-item md-size-25 md-small-size-100  text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>design_services</md-icon>
                    </div>
                    <h4 class="info-title">
                      Build
                    </h4>
                    <p>
                      Our developers get to work building your software – and test, test, test. 
                    </p>
                  </div>
                </div>
              <div class="md-layout-item md-size-25 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>settings_suggest</md-icon>
                    </div>
                    <h4 class="info-title">
                      Implement
                    </h4>
                    <p>
                      We help onboard your team to your new solution, using the full functionality of your software.  
                    </p>
                  </div>
                </div>
            <div class="md-layout-item md-size-100 mx-auto text-center">
              <h4 class="title">And repeat – as your organisation evolves and your needs change, we revise your solution and create more – exploring possibilities for where to go next to reach your goals. </h4>
                </div>
          </div>
      </div>
      </div>
        <div class="section section-grey">
    <div class="container">
          <div class="md-layout">
            <div class="md-layout-item">
              <h2 class="title">Our Approach</h2>
              <h4>Software development is our bread and butter. Our qualified and experienced team design and create effective solutions through a variety of platforms catered to our  clients’ specific needs. Because we are not affiliated with any specific hardware or software, our advice is geared to your organisation's needs, NOT those of our suppliers.</h4>
            </div>
            <div class="md-layout-item text-center">
                  <img src="@/assets/img/FC_Consult.png">
            </div>
          </div>
        </div>
        </div>
      <div class="section section-about-office">
        <div class="container">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h3 class="title">
                Interested in any of our services? Need help?
              </h3>
            </div>
            <div class="md-layout-item md-size-100 mx-auto text-center">
          <lets-talk />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import LetsTalk from "../components/LetsTalk";
export default {
  components: {
    LetsTalk
  },
  bodyClass: "software",
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      image: require("@/assets/img/FC_463651245.jpg"),
    };
  }
};
</script>
