<template>
  <div>
    <md-button class="md-primary md-lg" @click="signupModal = true">
      <md-icon>assignment</md-icon> Let's Talk
    </md-button>
    <div class="modal modal-bg" :class="{ show: signupModal }">
      <modal v-if="signupModal" modal-with-card type="modal-signup" card-classes="md-card-signup" @close="signupModal = false">
        <template slot="header">
          <md-button class="md-simple md-close md-just-icon md-round modal-default-button" @click="signupModal = false">
            <md-icon>clear</md-icon>
          </md-button>
          <h3 class="title text-center">
            Get In Touch
          </h3>
        </template>
        <template slot="body">
          <div class="md-layout">
            <div slot="content-left" class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 ml-auto">
              <div class="info info-horizontal left">
                <div class="description text-left">
                  <h4>New to Firecrest</h4>
                  <h4 class="info-title">How can we help</h4>
                  <p class="description">Get in touch to learn more about our services, or if you want to talk to one our consultants and see how we can work together.</p>
                  <info-areas  class="pt-0"  info-horizontal icon-color="primary" icon="mail">
                      <h4 slot="title" class="info-title">
                        Email us
                      </h4>
                      <div slot="content" class="description">
                        <p>
                          <a href="mailto:info@firecrest.co.nz">info@firecrest.co.nz</a>
                        </p>
                      </div>
                    </info-areas>
                    <info-areas  class="pt-0"  info-horizontal icon-color="primary" icon="phone">
                      <h4 slot="title" class="info-title">
                        Give us a ring
                      </h4>
                      <div slot="content" class="description">
                        <p>
                          <a href="tel:+6463535544">(06) 353 5544</a> <br>
                          Mon - Fri, 9:00am-5:00pm
                        </p>
                      </div>
                    </info-areas>
                </div>
              </div>
            </div>
            <div slot="content-right" class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 mr-auto">
              <div class="info info-horizontal">
                <div class="description text-left">
                  <h4>Support for existing clients</h4>
                  <h4 class="info-title">How can we help</h4>
                  <p class="description">We work hard to make sure our clients have, and understand, the technology available to them. If you require support please use one of these channels.</p>
                    <info-areas  class="pt-0"  info-horizontal icon-color="primary" icon="mail">
                      <h4 slot="title" class="info-title">
                        Email us
                      </h4>
                      <div slot="content" class="description">
                        <p>
                          <a href="mailto:support@firecrest.co.nz">support@firecrest.co.nz</a>
                        </p>
                      </div>
                    </info-areas>
                    <info-areas  class="pt-0"  info-horizontal icon-color="primary" icon="phone">
                      <h4 slot="title" class="info-title">
                        Give us a ring
                      </h4>
                      <div slot="content" class="description">
                        <p>
                          <a href="tel:+6463535544">(06) 353 5544</a> <br>
                          Mon - Fri, 9:00am-5:00pm
                        </p>
                      </div>
                    </info-areas>
                </div>
              </div>
              <!--<form>
                <md-field>
                  <label>Name</label>
                  <md-input v-model="data.inputs.name" required type="text" />
                </md-field>
                <md-field>
                  <label>Email</label>
                  <md-input v-model="data.inputs.email" required type="email" />
                </md-field>
                <md-field>
                  <label>Phone</label>
                  <md-input v-model="data.inputs.phone" type="text" />
                </md-field>
                <md-field>
                  <label id="serviceType">Subject</label>
                  <md-select name="serviceType" v-model="data.inputs.service" >
                    <md-option value="Custom Software Development">Custom Software Development</md-option>
                    <md-option value="Managed Device Service">Managed Device Service</md-option>
                    <md-option value="Content Driven Website">Content Driven Website</md-option>
                    <md-option value="Customer Care">Customer Care</md-option>
                    <md-option value="Design & Marketing">Design & Marketing</md-option>
                    <md-option value="POC Health Online">POC Health Online</md-option>
                    <md-option value="Not sure">Not sure</md-option>
                    <md-option value="Issue with a service">Issue with a service</md-option>
                  </md-select>
                </md-field>
                <md-field>
                  <label>Message</label>
                  <md-textarea v-model="data.inputs.message" required></md-textarea>
                </md-field>
                <div class="button-container justify-content-center">
                    <md-button href="javascript:void(0)" class="md-primary mt-3">
                      Send </md-button>
                </div>
              </form>-->
            </div>
            <div class="md-layout-item md-size-100">
                      <h4 slot="title" class="info-title">
                        Live Chat
                      </h4>
                  <p class="description">Or use our live chat feature below to talk to one of our staff.</p>
          </div>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Modal } from "@/components/material-kit";
import { InfoAreas } from "@/components/material-kit";

export default {
  components: {
    Modal,
    InfoAreas
  },
  data() {
    return {
      signupModal: false,
      data: {
        inputs: {
          name: "",
          email: "",
          phone: "",
          service: "",
          message: ""
        }
      }
    }}
};
</script>

<style lang="scss" scoped>
.left{
  border-right: solid 1px #ccc !important;
}
@media all and (max-width: 950px) {
.left {
    border-right: none !important;
  }
}
</style>
