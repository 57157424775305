<template>
    <div class="container">
      <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto text-center">
              <h2 class="title text-center">
                What we do
              </h2>
              <h5 class="description">
                We are here to help you with any technical or organisational challenges. If you have a project, an idea, or just want to review your business, we have a team of experts in a variety of fields ready to assist. We can answer your questions and provide you with advice you need to reach your goals.
              </h5>
            </div>
        <div class="md-layout-item md-size-33 md-small-size-100 services">

          <md-card class="bg-primary md-card-testimonial">
              <md-card-content>
                <h3 class="card-title text-center">
                  <a href="#/software">
                    <i class="material-icons">dns</i>
                     Custom Software Development</a>
                </h3>
                <hr>
                <h4 class="card-description">
                  <ul>
                    <li>Consultation</li>
                    <li>Business Analysis</li>
                    <li>Web Platforms</li>
                    <li>Internal Systems</li>
                    <li>Custom Plugins</li>
                    <li>Creative Solutions</li>
                  </ul>
                </h4>
                <div class="card-stats text-center">
                  <md-button  href="#/software" class="md-white" >
                    Find out more
                  </md-button>
                </div>
              </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100 services">
          <md-card class="bg-warning md-card-testimonial">
              <md-card-content>
                <h3 class="card-title text-center">
                  <a href="#/websites">
                    <i class="material-icons">dashboard</i>
                    Content Driven Website</a>
                </h3>
                <hr>
                <h4 class="card-description">
                  <ul>
                    <li>Custom Vue/.net Builds</li>
                    <li>Website Management</li>
                    <li>Client Assist</li>
                    <li>WordPress Design & Development</li>
                    <li>Hosting</li>
                    <li>Cloud Storage Solutions</li>
                    <li>Ongoing Support</li>
                  </ul>
                </h4>
                <div class="card-stats text-center">
                  <md-button href="#/websites" class="md-white md-block" >
                    Find out more
                  </md-button>
                </div>
              </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100 services">
          <md-card class="md-card-testimonial bg-grey">
              <md-card-content>
                <h3 class="card-title text-center text-grey">
                  <a href="#/customer-care">
                    <i class="material-icons">people</i>
                    Customer Care</a>
                </h3>
                <hr>
                <h4 class="card-description">
                  <ul>
                    <li>Helpdesk Services</li>
                    <li>Customer Phone Support</li>
                    <li>Customer Chatbot Support</li>
                    <li>Social Media Management</li>
                    <li>Email Management</li>
                  </ul>
                </h4>
                <div class="card-stats text-center">
                  <md-button href="#/customer-care" class="md-white md-block" >
                    Find out more
                  </md-button>
                </div>
              </md-card-content>
            </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-33 md-small-size-100 services">
          <md-card class="bg-indigo md-card-testimonial">
              <md-card-content>
                <h3 class="card-title text-center">
                  <a href="#/design">
                    <i class="material-icons">palette</i>
                    Design & Marketing</a>
                </h3>
                <hr>
                <h4 class="card-description">
                  <ul>
                    <li>Brand and Logo Development</li>
                    <li>Assessment / Consultation</li>
                    <li>Business Cards</li>
                    <li>Marketing Print Materials</li>
                    <li>Modernisation</li>
                  </ul>
                </h4>
                <div class="card-stats text-center">
                  <md-button href="#/design" class="md-white md-block" >
                    Find out more
                  </md-button>
                </div>
              </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100 services">
          <md-card class="bg-dark md-card-testimonial">
              <md-card-content>
                <h3 class="card-title text-center">
                  <a href="#/mds">
                    <i class="material-icons">build</i>
                    Managed Device Services</a>
                </h3>
                <hr>
                <h4 class="card-description">
                  <ul>
                    <li>Assessment / Consultation</li>
                    <li>Technology Procurement</li>
                    <li>I.T. Support & Helpdesk</li>
                    <li>Cloud Solutions & Microsoft 365</li>
                    <li>Email Services</li>
                    <li>Remote Management & Monitoring</li>
                    <li>Antivirus</li>
                    <li>Networks</li>
                  </ul>
                </h4>
                <div class="card-stats text-center">
                  <md-button href="#/mds" class="md-white md-block" >
                    Find out more
                  </md-button>
                </div>
              </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100 services">
          <md-card class="bg-info md-card-testimonial">
              <md-card-content>
                <h3 class="card-title text-center">
                  <a href="#/poch">
                    <i class="material-icons">health_and_safety</i>
                    POC Health Online</a>
                </h3>
                <hr>
                <h4 class="card-description">
                  <ul>
                    <li>Point of Care Health Software</li>
                    <li>Medical Professional Portal</li>
                    <li>INR & Gout Modules</li>
                    <li>Tools to manage patient health</li>
                    <li>Patient results and progress tracking</li>
                  </ul>
                </h4>
                <div class="card-stats text-center">
                  <md-button href="#/poch" class="md-white md-block" >
                    Find out more
                  </md-button>
                </div>
              </md-card-content>
            </md-card>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="scss"></style>
