<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Customer Stories
            </h1>
          </div>
        </div>
      </div>
    </parallax>

    <div id="main-panel" class="main main-raised">
      <div class="section">
        <div class="container">
        <div class="section section-sections">
          <div class="md-layout">
            <div class="md-layout-item">
                  <img src="@/assets/img/FC_cards.png">
            </div>
            <div class="md-layout-item">
              <h2 class="title">We provide many solutions for our customers</h2>
              <p>Each customer of our has their own specific needs and requirements. So we work hard to use the tools availble to us to come up with stategies for our customers and for their clients too.</p>
              <p>Have a look here for a few of the solutions we have provided</p>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-45 md-small-size-100 mx-auto">
              <h2 class="title text-center">Te Pū Harakeke—Community Collective Manawatū</h2>
                  <p class="text-center"><img src="@/assets/img/clients/2-colour-horozontal-Manawatu.png"></p>
              <h4 class="title">We provide</h4>
              <ul>
                <li>Content driven website</li>
                <li>Managed device support</li>
              </ul>
              <h4 class="title">What they say about us</h4>
              <p>Firecrest have been great to work with. The team are always quick to respond and make everything smooth and easy for us. From setting up a new Sharepoint, administering our website and our managed device network, no issue has been too small or too hard for the team, and for a great price. We highly recommend Firecrest for other not-for-profits or small businesses.</p>
                  
            </div>
            <div class="md-layout-item md-size-45 md-small-size-100 mx-auto">
              <h2 class="title text-center">Free FM</h2>
                  <p class="text-center"><img src="@/assets/img/clients/FreeFM89-Logo.png"></p>
              <h4 class="title">We provide</h4>
              <ul>
                <li>Content driven website</li>
                <li>Cloud media software</li>
                <li>Podcasting tools</li>
              </ul>
              <h4 class="title">What they say about us</h4>
              <p>Our podcasting platform, accessmedia.nz, really began delivering once we began working with Firecrest.  We’ve received outstanding levels of support which has built confidence amongst the twelve partner organisations and their own staff, and have been able to build it into an effective podcasting platform with Firecrest’s proactive planning and development suggestions.<br>
              Free FM has benefitted hugely from integrating our website with the podcast delivery functions of accessmedia.nz. Having both designed and supported by Firecrest means a cost-effective and highly functional online presence that gives us a big advantage in our corner of the media industry.<br>
              Reliable, responsive and proactive support and development is essential when you are delivering media both online and on demand.  Our partnership with Firecrest has underpinned our sector growth and relevance to our diverse audiences.</p>
            </div>
          </div>
        </div>
      <div class="section section-about-office">
        <div class="container">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              
              <h3 class="title">
                Interested in any of our services? Need Help?
              </h3>
            </div>
            <div class="md-layout-item md-size-100 mx-auto text-center">
          <lets-talk />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import LetsTalk from "./components/LetsTalk";
export default {
  components: {
    LetsTalk
  },
  bodyClass: "contact-us",
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      
      image: require("@/assets/img/FC_463548093.jpg"),
    };
  }
};
</script>
