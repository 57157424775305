<template>
  <div class="main-raised-page-wrapper">

    <div id="main-panel" class="main main-raised">
      <div class="section">
        <div class="container">
            <h1 class="title">
              Terms of Trade
            </h1>
                    <p><a href="https://cdn3.firecrestsystems.com/FC_Firecrest/Images/FC_Terms-Of-Trade.pdf" target="_blank">
                      <i class="material-icons">task</i> Firecrest Terms Of Trade PDF version</a></p>
          <ol>
              <li>All Firecrest clients are here after referred to as the Client.</li>
              <li>The most up to date Terms of Trade shall be made available via the Firecrest Systems
ltd website www.firecrest.co.nz</li>
              <li>Third Parties Responsibilities – Where problems occur with third parties Firecrest
Systems Limited shall work on behalf of the client to resolve all problems. Firecrest
Systems Limited will make every attempt to rectify any problems; however Firecrest
Systems Limited will not be held responsible for the actions of a third party.</li>
              <li>Firecrest Systems shall not be held responsible for the content displayed on the client’s
website.</li>
              <li>Illegal or Libellous Material - Firecrest Systems Limited is not required to reproduce and
material or produce any goods that are, in deemed in Firecrest Systems Limited’s
opinion, illegal, objectionable, or libellous in nature or that is in breach of any copyright,
patent, design or statute. Firecrest Systems Limited will be indemnified by the customer
in respect of any and all damages claim, costs and expenses (including actual legal
costs and disbursements on a solicitor and own client basis). For which Firecrest
Systems Limited may be liable for which it may suffer arising out of any liable, or
breach of statute or infringement of copyright, patent or design which may be
associated with the goods provided by the supplier to the customer.</li>
              <li>Request for modifications – Request for any major changes can only be made by a
previously listed contact. Major changes should wherever possible be requested in
written form.</li>
              <li>Claims – Firecrest Systems Limited must receive complaints regarding finished goods
within a reasonable time. What is “reasonable time” will depend on the circumstances
of each case, but not more than seven days.</li>
              <li>Payment is due in full on or before the due date of the invoice. If invoices are not paid
in full and on time the customer will pay collection and legal fees or commission
charged by debt collecting firms and actual legal costs and disbursements charged on a
solicitor and own client basis. In addition to the cost of recovery the customer will pay
penalty interest on any unpaid amount from the due date until payment is made in full
at the rate of 4% per month.</li>
              <li>Firecrest Systems Limited reserve the right to cancel all service should payment not be
received one month after the due date stated on the invoice.</li>
              <li>Currency - All prices unless otherwise stated are in New Zealand Dollars.</li>
              <li> Charges - The premium at the rate specified in Schedule shall apply for a period of the
contract from the date of the Agreement and shall continue thereafter unless the rate is
revised by the Supplier to cover increased costs. The Supplier will provide one month
advance written notice of any such revision to the Client.</li>
              <li>Termination - Any agreement shall remain in force for the duration stated from the date
of acceptance by the Client and thereafter until cancelled by either the Client or the
Supplier on one month advance written notice at the end of the current contract period.
Early termination will require full payment of the remainder of the agreed minimum
duration.</li>
              <li>Changes to Terms of Trade – Firecrest Systems ltd shall be entitled at any time by
notice in writing to vary any provision of these Terms of Trade and the Client shall be
bound by such variation. Notification of the terms of trade changes will be via
publication on the Firecrest website and the amended terms of Trade shall be operative
as from the date of publication of the amended Terms of Trade.</li>
              <li>Confidentiality - The Client shall at all times treat as confidential all non-public
information and material received from the Firecrest Systems Ltd and shall not publish,
release, or disclose the same without prior written consent. For clarity, confidential
information includes any new Intellectual Property and prices.</li>
              <li>Confidentiality after termination - The confidentiality provisions of this Agreement shall
remain in full force and effect after the termination of this Agreement.</li>
          </ol>
          
          <p>If you have any questions about our website terms and conditions, please feel free to contact us by email <a href="mailto:support@firecrest.co.nz">support@firecrest.co.nz</a></p>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import Mixins from "@/plugins/material-kit-mixins";
  
  export default {
    name: "Terms",
    mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
    data() {
      return {
        image: require("@/assets/img/FC-bk1.jpg")
      };
    }
  };
  </script>
  <style scoped>
  
  </style>

