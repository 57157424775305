<template>
  <div class="wrapper">
    
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              Get In Touch
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-contact">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 ml-auto">
              <h2 class="title mt-0">
                How can we help?
              </h2>
              <p class="description">
                We work hard to make sure our clients have, and understand, the technology available to them. If you are one of our clients and you have a problem, you can just give us a call.</p>
              <p class="description">
                Out of hours you can email and we will respond ASAP.</p>
              <p class="description">
                During office hours, you can use the chat window to talk to a Firecrest team member.</p>
              <p class="description">
                If you are just needing help with the IT involved in your industry, feel free to get in touch to see what we offer.
                <br>
                <br></p>
             <!-- <form>
                <md-field>
                  <label>Your Name</label>
                  <md-input
                    v-model="name"
                    type="text"
                  />
                </md-field>
                <md-field>
                  <label>Email Adress</label>
                  <md-input
                    v-model="email"
                    type="email"
                  />
                </md-field>
                <md-field>
                  <label>Phone</label>
                  <md-input
                    v-model="phone"
                    type="number"
                  />
                </md-field>
                <md-field>
                  <label>Your message</label>
                  <md-textarea v-model="textarea" />
                </md-field>
                <div class="submit text-center mt-3">
                  <md-button class="md-primary">
                    Contact Us
                  </md-button>
                </div>
              </form>-->
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3040.3588372334816!2d175.61091221744383!3d-40.3565671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d41b2da9b2cd745%3A0x6d2a51db38c8b261!2s1d%2F85%20The%20Square%2C%20Palmerston%20North%20Central%2C%20Palmerston%20North%204410!5e0!3m2!1sen!2snz!4v1685406963870!5m2!1sen!2snz" width="100%" height="750" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100 ml-auto">
              <info-areas  class="pt-0"  info-horizontal icon-color="primary" icon="mail">
                      <h4 slot="title" class="info-title">
                        Email us
                      </h4>
                      <div slot="content" class="description">
                        <p>New to Firecrest<br>
                          <a href="mailto:info@firecrest.co.nz">info@firecrest.co.nz</a>
                        </p>
                        <p>Support for existing clients<br>
                          <a href="mailto:support@firecrest.co.nz">support@firecrest.co.nz</a>
                        </p>
                      </div>
                    </info-areas>
                    <info-areas  class="pt-0"  info-horizontal icon-color="primary" icon="phone">
                      <h4 slot="title" class="info-title">
                        Give us a ring
                      </h4>
                      <div slot="content" class="description">
                        <p>
                          <a href="tel:+6463535544">(06) 353 5544</a> <br>
                          Mon - Fri, 9:00am-5:00pm
                        </p>
                      </div>
                    </info-areas>
              <info-areas
                class="pt-0"
                info-horizontal
                icon-color="primary"
                icon="pin_drop"
              >
                <h4
                  slot="title"
                  class="info-title"
                >
                  Find us at the office
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p> Unit 1D,<br>
                    85 The Square,<br>
                    Bryants Building,<br>
                    Palmerston North.<br>
                  </p>
                </div>
              </info-areas>
              <info-areas
                class="pt-0"
                info-horizontal
                icon-color="primary"
                icon="markunread_mailbox"
              >
                <h4
                  slot="title"
                  class="info-title"
                >
                  Post us mail
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p>P.O. Box 2026,<br>
                    Palmerston North, 4410.<br>
                  </p>
                </div>
              </info-areas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import { InfoAreas } from "@/components/material-kit";
export default {
  components: {
    InfoAreas
  },
  bodyClass: "contact-us",
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      textarea: null,
      
      image: require("@/assets/img/FC_74694322.jpg"),
    };
  }
};
</script>

<style lang="scss" scoped>
iframe:not(.md-image){
  height: 600px;
}
.info {
  padding: 30px 0 20px;
}
</style>
