<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Content Driven Websites
            </h1>
          </div>
        </div>
      </div>
    </parallax>

    <div id="main-panel" class="main main-raised">
      <div class="section section-sections">
    <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Our Web Services
            </h1>
          </div>
        </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                <div class="info">
                  <div class="icon icon-primary">
                    <md-icon>developer_mode</md-icon>
                  </div>
                  <h4 class="info-title">
                    Custom Builds
                  </h4>
                  <p>
                    Whether you need a simple WordPress build, or a custom site with intergrated management tools, you can rely on us to provide the best solution. 
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>cloud_upload</md-icon>
                    </div>
                    <h4 class="info-title">
                      Hosting
                    </h4>
                    <p>
                      If you have an existing website, Firecrest can host it for you using our own secure services. 
                    </p>
                  </div>
                </div>
              
              <div class="md-layout-item md-size-33 md-small-size-100  text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>contact_support</md-icon>
                    </div>
                    <h4 class="info-title">
                      Client Assist
                    </h4>
                    <p>
                      Need to make changes to your website? Your time is precious, so we provide ongoing website support so you can focus on doing what you do best.
                    </p>
                  </div>
                </div>
          </div>
      </div>
      </div>
        <div class="section section-dark">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item">
              <h2 class="title">Our Approach</h2>
              <h4 class="description">You need to outperform your competitors. A well-designed and effective website forms an important first impression for your prospective customers. Our professional designers and developers have the experience and expertise to help you build the website your organisation's needs.  </h4>
            </div>
            <div class="md-layout-item">
                  <img src="@/assets/img/FC_webworking.png">
            </div>
          </div>
        </div>
    </div>
        <div class="section section-grey">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
              <h2 class="title">Our Process</h2>
                </div>
            <div class="md-layout-item md-size-25 md-small-size-100 text-center">
                <div class="info">
                  <div class="icon icon-primary">
                    <md-icon>live_help</md-icon>
                  </div>
                  <h4 class="info-title">
                    Consultation
                  </h4>
                  <p>
                    We sit with you and learn about your requirements and current barriers, and produce a plan of action to get you moving.
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>polyline</md-icon>
                    </div>
                    <h4 class="info-title">
                      Design
                    </h4>
                    <p>
                      We design the website to your vision while also considering modern standards and effectibility.
                    </p>
                  </div>
                </div>
              
              <div class="md-layout-item md-size-25 md-small-size-100  text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>design_services</md-icon>
                    </div>
                    <h4 class="info-title">
                      Build
                    </h4>
                    <p>
                      We build the website for you using one of our many platform options.
                    </p>
                  </div>
                </div>
              <div class="md-layout-item md-size-25 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>settings_suggest</md-icon>
                    </div>
                    <h4 class="info-title">
                      Training
                    </h4>
                    <p>
                      We present the website to you and your team and walk you through how to use it.
                    </p>
                  </div>
                </div>
          </div>
          </div>
    </div>
      <div class="section section-about-office">
        <div class="container">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h3 class="title">
                Interested in any of our services? Need help?
              </h3>
            </div>
            <div class="md-layout-item md-size-100 mx-auto text-center">
          <lets-talk />
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import LetsTalk from "../components/LetsTalk";
export default {
  components: {
    LetsTalk
  },
  bodyClass: "contact-us",
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      
      image: require("@/assets/img/FC_460013625.jpg"),
    };
  }
};
</script>