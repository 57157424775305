<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Customer Care
            </h1>
            <h3>
              Outsource your helpdesk
            </h3>
          </div>
        </div>
      </div>
    </parallax>

    <div id="main-panel" class="main main-raised">
      <div class="section section-sections">
    <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
              <h2 class="title">Helping your clients engage with you. Caring for your customers.</h2>
                </div>
            <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                <div class="info">
                  <div class="icon icon-primary">
                    <md-icon>support_agent</md-icon>
                  </div>
                  <h4 class="info-title">
                    Help Desk
                  </h4>
                  <p>
                    Add a chatbox to your website or service, and staff it with our technicians. Your customers can get instant answers and assistance on your platform, and we can even do the admin work.
                  </p>
                </div>
              </div>
              
              <div class="md-layout-item md-size-33 md-small-size-100  text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>phone_in_talk</md-icon>
                    </div>
                    <h4 class="info-title">
                      Phone Services
                    </h4>
                    <p>
                      Get a custom phone line and our staff to answer the calls freeing you up for the more important stuff.
                    </p>
                  </div>
                </div>
              <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>local_post_office</md-icon>
                    </div>
                    <h4 class="info-title">
                      Email Services
                    </h4>
                    <p>
                      Create a support email for your organisation, and we’ll monitor the inbox. Our technicians will respond accordingly – in a timely, professional manner.
                    </p>
                  </div>
                </div>
          </div>
      </div>
      </div>
      <div class="section section-about-office">
        <div class="container">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              
              <h3 class="title">
                Interested in any of our services? Need help?
              </h3>
            </div>
            <div class="md-layout-item md-size-100 mx-auto text-center">
          <lets-talk />
            </div>
          </div>
        </div>
    </div>
  </div>
    </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import LetsTalk from "../components/LetsTalk";
export default {
  components: {
    LetsTalk
  },
  bodyClass: "contact-us",
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      
      image: require("@/assets/img/FC_465765331.jpg"),
    };
  }
};
</script>
<style lang="scss" scoped>

.page-header:after {
  background: rgba(0, 0, 0, 0.60) !important;
}

</style>