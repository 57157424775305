<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Managed Device Services
            </h1>
          </div>
        </div>
      </div>
    </parallax>

    <div id="main-panel" class="main main-raised">
      <div class="section section-sections">
    <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
              <h3>Why employ an I.T. Person when Firecrest can provide all your I.T. solutions reliably, without paying the expensive salary? Our Managed Device Services aims to take the headache of technology off your hands. MDS ranges from hardware and software procurement, onsite and offsite support, antivirus solutions, backups, networking, CRM solutions, and more.</h3>
                </div>
                  </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                <div class="info">
                  <div class="icon icon-primary">
                    <md-icon>devices</md-icon>
                  </div>
                  <h4 class="info-title">
                    Remote Support
                  </h4>
                  <p>
                    Computer stopped working? Our support team are one call away from remotely accessing your device and providing assistance. 
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>safety_check</md-icon>
                    </div>
                    <h4 class="info-title">
                      Device Protection
                    </h4>
                    <p>
                      Protect your devices with enterprise level endpoint protection (antivirus) that reports any issues back to a centralised portal that Firecrest monitors. 
                    </p>
                  </div>
                </div>
              
              <div class="md-layout-item md-size-33 md-small-size-100  text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>mark_email_read</md-icon>
                    </div>
                    <h4 class="info-title">
                      Email Services
                    </h4>
                    <p>
                      Create a personalized email for yourself and your team to provide a professional online presence to your customers.
                    </p>
                  </div>
                </div>
          </div>
      </div>
      </div>
        <div class="section section-dark">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item">
              <h2 class="title">Why do I need it?</h2>
              <h4 class="description">MDS is a cost-effective solution designed to meet the I.T. needs of small to medium businesses. Taking a proactive approach to I.T. management, we work behind the scenes to prevent issues before they arise - so you can stay productive. </h4>
            </div>
            <div class="md-layout-item">
                  <img src="@/assets/img/FC_webworking.png">
            </div>
          </div>
        </div>
    </div>
        <div class="section section-grey">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item">
              <h2 class="title">Our Process</h2>
          </div>
        </div>
          <div class="md-layout">
            <div class="md-layout-item">
                  <img src="@/assets/img/FC_process.png">
            </div>
            <div class="md-layout-item">
              <h4 class="title">1. Consultation</h4>
              <p>Together we discuss your business needs and how we can support them through our services</p>
              <h4 class="title">2. Strategy</h4>
              <p>We'll present a strategy that fits your business requirements, timeframe, and budget </p>
              <h4 class="title">3. Onboarding</h4>
              <p>You're all sorted! Time to relax knowing that Firecrest is providing you with proactive I.T. support</p>
            </div>
          </div>
        </div>
    </div>
      <div class="section section-about-office">
        <div class="container">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              
              <h3 class="title">
                Interested in any of our services? Need help?
              </h3>
            </div>
            <div class="md-layout-item md-size-100 mx-auto text-center">
          <lets-talk />
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import LetsTalk from "../components/LetsTalk";
export default {
  components: {
    LetsTalk
  },
  bodyClass: "contact-us",
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      
      image: require("@/assets/img/FC_Charts.jpg"),
    };
  }
};
</script>
<style lang="scss" scoped>

.page-header:after {
  background: rgba(0, 0, 0, 0.60) !important;
}

</style>
