<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Design & Marketing
            </h1>
          </div>
        </div>
      </div>
    </parallax>

    <div id="main-panel" class="main main-raised">
      <div class="section section-sections">
    <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
              <h2 class="title">Get the look of your business up-to-date and working for you.</h2>
              <h4>Planning is essential for most businesses and organisations and your brand guidelines and marketing materials are a key part of this.
Sometimes the ever-busy, dynamic nature of running a business can make this difficult. But with our help we think it is
worth taking a bit of time to get it right.</h4>
                </div>
            <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                <div class="info">
                  <div class="icon icon-primary">
                    <md-icon>palette</md-icon>
                  </div>
                  <h4 class="info-title">
                    Brand Update
                  </h4>
                  <p>
                    We update your brand, redesigning your look, logo and providing all the files you need to guide you through this.
                  </p>
                </div>
              </div>
              
              <div class="md-layout-item md-size-33 md-small-size-100  text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>print</md-icon>
                    </div>
                    <h4 class="info-title">
                      Print Services
                    </h4>
                    <p>
                      We use personal and individual service to provide the best price and printing solutions for you. We even use environmentally friendly printing.
                    </p>
                  </div>
                </div>
              <div class="md-layout-item md-size-33 md-small-size-100 text-center">
                  <div class="info">
                    <div class="icon icon-primary">
                      <md-icon>featured_video</md-icon>
                    </div>
                    <h4 class="info-title">
                      Advertisements
                    </h4>
                    <p>
                      We can create adverts for your business and apply them to a number of formats - from printed flyers, posters and folders to digital marketing campaigns.
                    </p>
                  </div>
                </div>
          </div>
      </div>
      </div>
      <div class="section section-about-office">
        <div class="container">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h2 class="title">
                Interested in any of our services? Need help?
              </h2>
            </div>
            <div class="md-layout-item md-size-100 mx-auto text-center">
          <lets-talk />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import LetsTalk from "../components/LetsTalk";
export default {
  components: {
    LetsTalk
  },
  bodyClass: "contact-us",
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      
      image: require("@/assets/img/FC-251938.jpg"),
    };
  }
};
</script>
<style lang="scss" scoped>

.page-header:after {
  background: rgba(0, 0, 0, 0.60) !important;
}

</style>