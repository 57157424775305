<template>
  <div class="wrapper">
    <div id="footer-areas">
      <!-- Big Footer -->
      <footer class="footer footer-black footer-big">
        <div class="container">
          <div class="content">
            <div class="md-layout">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <p><img src="@/assets/img/FC-whiteWeb.png"/></p>
                <h3><a href="tel:+6463535544">(06) 353 5544</a><br>
                  <a href="mailto:info@firecrest.co.nz">info@firecrest.co.nz</a>
                </h3>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <h6>Firecrests – the birds</h6>
                <p>Little birds, travellers, flock of birds, constantly busy, songsters, very neat, extremely successful ecological adaptors.</p>
                <h6>Firecrest Systems – us</h6>
                <p>Much of the software developed by Firecrest Systems has been named after birds. It’s kind of appropriate – technology that’s tidy, compact, powerful, high revving, and innovative.
                </p>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100 text-center">
                <p><a href="https://www.manawa.tech/company/79-firecrest-systems" target="_blank"><img src="@/assets/img/ManawaTech_FoundingMember_Dark.svg" width="130px"/></a></p>
                <br>
                <h6>Partnered with</h6>
                <p><a href="https://aws.amazon.com/" target="_blank"></a><img src="@/assets/img/sponsor.cache-1643399762.png" width="100px"/></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- end Big Footer -->
  <footer class="footer" :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size}"
    :data-background-color="backgroundColor">
    <template>
      <div class="container">
        <nav>
          <ul>
            <li>
              <a href="#/terms">Terms and Conditions</a>
            </li>
            <li>
              <a href="#/privacy">Privacy Policy</a>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          &copy; {{ year }}, developed by
          <a href="https://firecrest.co.nz/"  target="_blank">Firecrest Systems</a>
        </div>
      </div>
    </template>
  </footer>
      </div>
      </div>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
</style>
