<template>
  <div class="main-raised-page-wrapper">
    <parallax
      class="page-header header-filter header-small"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Documents For Clients
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div id="main-panel" class="main main-raised">
  <!-- Services Section -->
        <div class="section section-sections" id="services">
          <services-block />
          <br>
          <div class="md-layout-item md-size-100 md-small-size-100 mx-auto text-center">
              <h4>Interested in any of our services?</h4>
            <lets-talk />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import LetsTalk from "./components/LetsTalk";
import ServicesBlock from "./components/ServicesBlock";

export default {
  name: "Index",
  components: {
    LetsTalk,
    ServicesBlock,
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="scss"></style>
